<template>
    <div class="v-sign-contract">
        <Header />
        <div class="container">
            <div class="pages">
                <img src="@/assets/1.png" alt="1">
                <img src="@/assets/2.png" alt="2">
                <img src="@/assets/3.png" alt="3">
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../components/SignHeader"

export default {
    data: function() {
        return {}
    },
    components: {
        Header,
    },
}

</script>

<style lang="sass">

div.v-sign-contract

    div.pages
        display: flex
        justify-content: center
        align-items: center
        flex-direction: column
        padding-top: 20px

        img
            margin: 0 10px 10px 10px
            max-width: 1000px
            width: 100%

</style>