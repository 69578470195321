<template>
    <header class="c-sign-header">
        <div class="container">
            <div class="left">
                <img src="@/assets/logo.png" alt="logo" class="logo">
                <div class="info">
                    <p class="title">Sign the contrAct</p>
                    <p class="descr">Document FileName to Sign.pdf</p>
                </div>
            </div>
            <div class="right">
                <div class="start-btn">
                    <span>start</span>
                    <img src="@/assets/right-arrow.svg" alt="right-arrow">
                </div>
            </div>
        </div>
    </header>
</template>

<script>

export default {
    data: function() {
        return {}
    }
}

</script>

<style lang="sass">

header.c-sign-header
    background: #fff
    padding: 12px

    div.container
        display: flex
        justify-content: space-between
        align-items: center

    div.left
        display: flex
        align-items: center

    img.logo
        margin-right: 15px

    div.info
        position: relative
        padding-left: 15px
        border-left: 2px solid rgba(64, 76, 100, .12)
        &:before
            content: ""
            display: block
            position: absolute
            left: 0
            top: 50%
            transform: translate(-60%, -50%)
            border: 2px solid rgba(64, 76, 100, .12)
            width: 5px
            height: 5px
            border-radius: 50%
            background: #fff

    p.title
        font-size: 10px
        line-height: 100%
        letter-spacing: 0.02em
        text-transform: uppercase
        color: #404C64
        opacity: 0.5
        
    p.descr
        font-size: 18px
        line-height: 110%
        letter-spacing: 0.02em
        color: #171717

    div.start-btn
        display: flex
        justify-content: center
        align-items: center
        width: 97px
        height: 44px
        color: #fff
        font-weight: 600
        font-size: 14px
        line-height: 22px
        text-align: center
        letter-spacing: 0.02em
        text-transform: uppercase
        background: #F77B00
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
        border-radius: 3px
        cursor: pointer

        img
            max-width: 10px
            margin-left: 6px

</style>